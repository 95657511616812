import * as yup from 'yup'

import { validateUrl } from 'utils/url'

export const FORM_VALIDATION_MESSAGE_REQUIRED = 'This is a required field'

const configure = () => {
  yup.setLocale({
    mixed: {
      notType: FORM_VALIDATION_MESSAGE_REQUIRED,
      required: FORM_VALIDATION_MESSAGE_REQUIRED,
    },
  })
}

yup.addMethod(yup.string, 'url', function urlValidate(errorMessage: string) {
  return this.test(
    'valid-url',
    errorMessage,
    function testUrl(value: string | undefined) {
      const { path, createError } = this
      if (!value) {
        return true
      }
      const urlWithoutProtocols = value.replace(
        /^(?:https?:\/\/)?(?:www\.)?/i,
        ''
      )

      return (
        validateUrl({ url: urlWithoutProtocols }) ||
        createError({ path, message: errorMessage })
      )
    }
  )
})

export const YupConfig = { configure }
