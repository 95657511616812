import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useApolloClient } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import {
  AuthError,
  confirmPasswordReset as firebaseConfirmPasswordReset,
  connectAuthEmulator,
  getAuth,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
  updatePassword,
} from 'firebase/auth'
import { once } from 'lodash'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { Route } from 'constants/routes'
import {
  Brand,
  QueryUserMeArgs,
  SignUpInput,
  useHasActiveSubscriptionLazyQuery,
  User,
  UserMeDocument,
  UserMeQuery,
  UserType,
  Venue,
} from 'generated/generated-graphql'
import { FirebaseApp } from 'utils/firebase'
import { removeSavedSelectedVenueId } from 'utils/handleSavedSelectedVenue'
import { useErrorToast } from 'utils/toast'

export type OmittedUserData = Omit<User, 'venues'> & {
  activeVenue: Venue | null
  activeBrand: Brand | null
}

interface EmailAndPasswordInput {
  email: string
  password: string
}

interface ConfirmPasswordResetInput {
  resetCode: string
  password: string
}

interface UserContextType {
  isLoading: boolean
  isFirebaseInitializing: boolean
  userData: OmittedUserData | null
  signOut: (shouldRedirect?: boolean) => Promise<void>
  signInWithEmailAndPassword: ({
    email,
    password,
  }: Pick<SignUpInput, 'email' | 'password'>) => Promise<void>
  signInWithFirebaseToken: (token: string) => Promise<void>
  refetchAndSetUserData: ({
    activeVenueId,
  }?: {
    activeVenueId?: number
  }) => Promise<void>
  setActiveVenue: (selectedVenue: Venue | null) => void
  sendResetPasswordEmail: ({ email }: { email: string }) => Promise<boolean>
  confirmPasswordReset: ({
    resetCode,
    password,
  }: ConfirmPasswordResetInput) => Promise<boolean>
  changePassword: (newPassword: string) => Promise<void> | null
  isVenue: boolean
  isBrand: boolean
  canCreateTender: boolean
}

interface Props {
  children: ReactNode
}

const GENERAL_PERSISTS_ERROR = 'TRANSLATION_135'

const FirebaseAuth = getAuth(FirebaseApp)

if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST) {
  once(() => {
    connectAuthEmulator(
      FirebaseAuth,
      process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST!
    )
  })()
}

const UserContext = createContext({} as UserContextType)

export const useAuth = (): UserContextType => useContext(UserContext)

const UserProvider = ({ children }: Props) => {
  const { t } = useTranslation('common')
  const [userData, setUserData] = useState<OmittedUserData | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isFirebaseInitializing, setIsFirebaseInitializing] = useState(true)
  const errorToast = useErrorToast()
  const router = useRouter()
  const apolloClient = useApolloClient()
  const [refetchActiveSubscription] = useHasActiveSubscriptionLazyQuery()

  const isVenue = userData?.userType === UserType.Venue
  const isBrand = userData?.userType === UserType.Brand
  const canCreateTender = Boolean(
    isVenue && userData.activeVenue?.canCreateTender
  )

  useEffect(() => {
    if (isFirebaseInitializing) {
      return
    }
    window.dataLayer.push({
      event: 'login',
      userId: userData?.id || undefined,
    })
    // WORKAROUND: disabled because we want to run this only when firebase
    //  finishes fetching on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirebaseInitializing])

  useEffect(() => {
    if (isFirebaseInitializing) {
      return () => {}
    }

    const handleRouteChangeComplete = () => {
      window.dataLayer.push({
        event: 'login',
        userId: userData?.id || undefined,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [router.events, userData?.id, isFirebaseInitializing])

  const signInWithEmailAndPassword = useCallback(
    async ({ email, password }: EmailAndPasswordInput) => {
      try {
        setIsLoading(true)
        removeSavedSelectedVenueId()
        await firebaseSignInWithEmailAndPassword(FirebaseAuth, email, password)
        await refetchActiveSubscription({
          fetchPolicy: 'network-only',
        })
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorToast({
              description: t('TRANSLATION_136'),
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              description: t('TRANSLATION_140'),
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: t(GENERAL_PERSISTS_ERROR),
            })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [errorToast, refetchActiveSubscription, t]
  )

  const signInWithFirebaseToken = useCallback(
    async (token: string) => {
      try {
        setIsLoading(true)
        removeSavedSelectedVenueId()
        await firebaseSignInWithCustomToken(FirebaseAuth, token)
        await refetchActiveSubscription({
          fetchPolicy: 'network-only',
        })
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorToast({
              description: t('TRANSLATION_136'),
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              description: t('TRANSLATION_140'),
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: t(GENERAL_PERSISTS_ERROR),
            })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [errorToast, refetchActiveSubscription, t]
  )

  const signOut = useCallback(
    async (shouldRedirect = true) => {
      try {
        setIsLoading(true)
        await FirebaseAuth.signOut()
        removeSavedSelectedVenueId()
        if (shouldRedirect) {
          void router.push(Route.SignIn())
        }
      } catch (error) {
        Sentry.captureException(error)
      } finally {
        setIsLoading(false)
      }
    },
    [router]
  )

  const changePassword = useCallback(
    (newPassword: string) =>
      FirebaseAuth.currentUser &&
      updatePassword(FirebaseAuth.currentUser, newPassword),
    []
  )

  const refetchAndSetUserData = useCallback(
    async ({ activeVenueId }: { activeVenueId?: number } = {}) => {
      try {
        const response = await apolloClient.query<UserMeQuery, QueryUserMeArgs>(
          {
            query: UserMeDocument,
            ...(activeVenueId && {
              variables: { input: { activeVenueId } },
            }),
            fetchPolicy: 'network-only',
          }
        )
        if (response.errors && response.errors.length > 0) {
          response.errors.forEach((error) => {
            Sentry.withScope((scope) => {
              scope.setContext('Additional Data', { scope })
              Sentry.captureException(new Error(error.message))
            })
            errorToast({ description: error.message })
          })
        }
        if (response.data?.userMe) {
          Sentry.setUser({ email: response.data.userMe.email })
          setUserData((prevData) => ({
            ...response.data.userMe,
            activeBrand: response.data.userMe.brands[0] ?? null,
            activeVenue:
              response.data.userMe.activeVenue ??
              prevData?.activeVenue ??
              (response.data.userMe.firstFourVenues.length === 1 &&
              response.data.userMe.firstFourVenues[0]
                ? response.data.userMe.firstFourVenues[0]
                : null),
          }))
        } else {
          errorToast({
            description: t(GENERAL_PERSISTS_ERROR),
          })
        }
      } catch (error) {
        Sentry.captureException(error)
        await signOut()
      }
    },
    [apolloClient, errorToast, signOut, t]
  )

  const setActiveVenue = useCallback((selectedVenue: Venue | null) => {
    setUserData((prevData) =>
      prevData
        ? {
            ...prevData,
            activeVenue: selectedVenue,
          }
        : null
    )
  }, [])

  const sendResetPasswordEmail = useCallback(
    async ({ email }: { email: string }) => {
      try {
        setIsLoading(true)
        await firebaseSendPasswordResetEmail(FirebaseAuth, email)
        setIsLoading(false)
        return true
      } catch (error) {
        const err = error as AuthError
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/user-not-found':
            errorToast({
              title: t('TRANSLATION_138'),
              description: t('TRANSLATION_139'),
            })
            break
          case 'auth/network-request-failed':
            errorToast({
              title: t('TRANSLATION_138'),
              description: t('TRANSLATION_140'),
            })
            break
          default:
            Sentry.captureException(err)
            errorToast({
              description: GENERAL_PERSISTS_ERROR,
            })
        }
        setIsLoading(false)
        return false
      }
    },
    [errorToast, t]
  )

  // TODO change redirect URL in Firebase console from localhost:3000 to real one
  const confirmPasswordReset = useCallback(
    async ({ resetCode, password }: ConfirmPasswordResetInput) => {
      try {
        setIsLoading(true)
        await firebaseConfirmPasswordReset(FirebaseAuth, resetCode, password)
        setIsLoading(false)
        return true
      } catch (error) {
        Sentry.captureException(error)
        setIsLoading(false)
        return false
      }
    },
    []
  )

  useEffect(() => {
    const subscribe = FirebaseAuth.onAuthStateChanged((firebaseUser) => {
      if (router.asPath === Route.FirebaseSignIn()) {
        /**
         * We don't want to fetch user when he's on firebaseSignInPage, since he
         * will most likely be signing in as another user
         * HOWEVER: there may be a bug where some signed-in user navigates
         * to this page, and he will be redirected to dashboard, but his data
         * won't be fetched because this observer won't be re-mounted. Keep this
         * in mind when discovering future bugs.
         */
        return
      }
      if (firebaseUser) {
        void (async () => {
          await refetchAndSetUserData()
          setIsFirebaseInitializing(false)
        })()
      } else {
        setUserData(null)
        setIsFirebaseInitializing(false)
      }
    })
    return subscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contextValue = useMemo(
    () => ({
      userData,
      isLoading,
      isFirebaseInitializing,
      signInWithEmailAndPassword,
      signInWithFirebaseToken,
      signOut,
      refetchAndSetUserData,
      setActiveVenue,
      sendResetPasswordEmail,
      confirmPasswordReset,
      isVenue,
      isBrand,
      canCreateTender,
      changePassword,
    }),
    [
      userData,
      isLoading,
      isFirebaseInitializing,
      signInWithEmailAndPassword,
      signInWithFirebaseToken,
      signOut,
      refetchAndSetUserData,
      setActiveVenue,
      sendResetPasswordEmail,
      confirmPasswordReset,
      isVenue,
      isBrand,
      canCreateTender,
      changePassword,
    ]
  )

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  )
}
export default UserProvider
